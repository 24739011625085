<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Questionnaire</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <!-- <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select name="" id="" v-model="user_type" class="form-control form-control-sm">
                        <option value="all">All</option>
                        <option value="superuser">superuser</option>
                          <option value="trainer">trainer</option>
                          <option value="student">student</option>
                      </select>
                    </div>
                  </div> -->
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>Question</th>
                  <th>Options</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.question }}</td>
                      <td>{{ item.options }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>Active</b></span
                        >
                        <span class="text-warning" v-else><b>Inactive</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
            <div class="parent margin-top-15" v-if="visible_form">
              <div class="parent-body pad-0">
                <div class="row margin-0">
                  <div class="col pad-0">
                    <h4 v-if="!payload.id" class="text-center">
                      Add New Questionnaire
                    </h4>
                    <h4 v-if="payload.id" class="text-center">Update Domain</h4>
                    <div class="row margin-0 margin-bottom-5">
                      <div class="col-6">
                        <div class="form-group">
                          <label for=""><b>Question</b></label>
                          <textarea
                            v-model="payload.question"
                            class="form-control form-control-sm"
                            placeholder="Question"
                          />
                          <span class="error" v-if="error && error.question">{{
                            error.question
                          }}</span>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for=""><b>Options</b></label>
                          <template v-for="(item, index) of payload.options" :key="index">
                            <div style="display: flex;">
                              <input
                                type="text"
                                v-model="payload.options[index]"
                                class="form-control form-control-sm margin-bottom-5"
                                placeholder="Enter Option"
                              />
                              <span @click="removingOption(index)" class="fa fa-trash margin-left-10 margin-top-5 text-danger c-pointer"></span>
                            </div>
                          </template>
                          <div class="pad-top-10">
                            <button class="btn btn-sm btn-primary" @click="payload.options.push('')">+ Add</button>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label for=""><b>Status</b></label>
                          <select
                            name=""
                            id=""
                            v-model="payload.status"
                            class="form-control form-control-sm"
                          >
                            <option value="active">Active</option>
                            <option value="inactive">InActive</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 text-right">
                        <button
                          v-if="!payload.id"
                          @click="newData()"
                          class="btn btn-success btn-sm margin-right-10"
                        >
                          Create
                        </button>
                        <button
                          v-if="payload.id"
                          @click="updateData()"
                          class="btn btn-success btn-sm margin-right-10"
                        >
                          Update
                        </button>
                        <button
                          @click="visible_form = false"
                          class="btn btn-light btn-sm"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "questionnaire",
      defaultPayload: {
        question: null,
        options: [],
        status: "active",
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.question === null || that.payload.question === "") {
        this.error.question = "Question is required";
        proceed = false;
      }
      return proceed;
    },
    resetPayload() {
      this.payload = {
        question: null,
        options: [],
        status: "active",
      };
      this.error = {};
    },
    removingOption(index) {
      this.payload.options.splice(index, 1);
    }
  },
};
</script>
<style lang="stylus" scoped></style>